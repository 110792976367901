<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 overflow-auto">
        <b-col sm="12" md="10" lg="10" class="mx-auto">
          <div class="d-flex justify-content-between mb-1">
            <b-card-title title-tag="h4" class="fw-bold mb-0">
              Latest job
            </b-card-title>
            <p class="text-primary m-0">
              <u style="cursor: pointer" @click="skipped"
                >Skip, I am a Fresher</u
              >
            </p>
          </div>
          <hr class="mt-0 mb-1" />
        </b-col>
        <!-- form -->
        <b-col sm="12" md="10" lg="10" class="mx-auto pb-3">
          <validation-observer ref="simpleRules">
            <b-form class="mt-1">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Designation" label-for="designation">
                    <validation-provider
                      #default="{ errors }"
                      name="Designation"
                      rules="required"
                    >
                      <b-form-input
                        id="designation"
                        v-model="form.designation"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Designation"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Company" label-for="company">
                    <validation-provider
                      #default="{ errors }"
                      name="Company"
                      rules="required"
                    >
                      <b-form-input
                        id="company"
                        v-model="form.company"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Company"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Industry" label-for="industry">
                    <v-select
                      ref="selectIndustry"
                      v-model="form.selectIndustry"
                      multiple
                      label="name"
                      :options="industries"
                      :get-option-label="getSelected"
                    /> </b-form-group
                ></b-col>

                <b-col cols="12">
                  <b-form-group label="Function" label-for="function">
                    <v-select
                      ref="selectFunction"
                      v-model="form.selectFunction"
                      multiple
                      label="name"
                      :options="candidateFunctions"
                      :get-option-label="getSelected"
                    /> </b-form-group
                ></b-col>

                <b-col cols="12">
                  <b-form-group label="Sub Function" label-for="subFunction">
                    <v-select
                      ref="selectFunction"
                      v-model="form.selectSubFunction"
                      multiple
                      label="name"
                      :options="subFunctions"
                      :get-option-label="getSelected"
                    /> </b-form-group
                ></b-col>

                <b-col cols="12">
                  <b-form-group label="Select Your Employment Type">
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="form.employment_type"
                        value="full-time"
                      >
                        Full Time
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.employment_type"
                        value="part-time"
                      >
                        Part Time
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.employment_type"
                        value="self-employed"
                      >
                        Self Employed
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.employment_type"
                        value="internship"
                      >
                        Internship
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Are You Presentely Working Here ?"
                    label-for=""
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio v-model="form.is_present" value="true">
                        Yes
                      </b-form-radio>
                      <b-form-radio v-model="form.is_present" value="false">
                        No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="From" label-for="">
                    <b-form-datepicker
                      id="datepicker-dateformat1"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      locale="en"
                      v-model="form.from"
                      placeholder="Choose a date"
                    />
                    <!-- <b-form-datepicker id="datepicker-placeholder"  local="en"  /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="To"
                    label-for=""
                    v-if="form.is_present === 'false'"
                  >
                    <b-form-datepicker
                      id="datepicker-dateformat2"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      locale="en"
                      v-model="form.to"
                      placeholder="Choose a date"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Country" label-for="country">
                    <v-select
                      ref="selectCountry"
                      v-model="form.country"
                      label="name"
                      :options="countries"
                      :get-option-label="getSelected"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="City" label-for="city">
                    <b-form-input v-model="form.city" />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Job Responsibilities">
                    <b-form-textarea
                      id="textarea-rows"
                      v-model="form.job_description"
                      placeholder="Tall textarea"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>

                <!-- login button -->
                <b-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    class="my-2"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Next
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from "@/libs/axios"
import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { required } from "@validations"
import {
  BButton,
  BCardTitle,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BImg,
  BLink,
  BRow,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import vSelect from "vue-select"

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BFormRadio,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormGroup,
    BFormInput,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        designation: null,
        company: null,
        selectIndustry: [],
        selectFunction: [],
        selectSubFunction: [],
        employment_type: null,
        is_present: "false",
        from: null,
        to: null,
        country: null,
        city: null,
        job_description: null,
      },

      input: {
        industry_id: 1,
        function_id: 1,
        sub_function_id: 1,
        country_id: 1,
      },

      industries: [],
      candidateFunctions: [],
      subFunctions: [],
      countries: [],

      sideImg: require("@/assets/images/pages/register-v2.svg"),

      // validation
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },

  created() {
    // setTimeout(() => {
    //   axios.all([this.fetchIndustries(), this.fetchFunctions(), this.fetchSubFunctions(), this.fetchCountries()]);
    // }, 500);

    this.fetchIndustries()
    this.fetchFunctions()
    this.fetchSubFunctions()
    this.fetchCountries()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.submitForm()
        }
      })
    },
    submitForm() {
      store.dispatch("auth/presentJob", this.form).then((response) => {
        if (response.data.status === "success") {
          this.$router.push({ name: "paid-consulting-form" })

          // notified
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Present Job Details Submitted",
              icon: "CoffeeIcon",
              variant: "success",
            },
          })
        }
      })
    },
    fetchIndustries() {
      // axios.get('/industries').then((response) => {
      //   response.data.industries.forEach((industry) => {
      //     this.industries.push(industry);
      //   });
      // });
      store.dispatch("auth/getIndustries").then((response) => {
        if (response.status === 200) {
          this.industries = store.state.auth.industries
        }
      })
    },
    fetchFunctions() {
      // axios.get('/functions').then((response) => {
      //   response.data.candidateFunctions.forEach((candidateFunction) => {
      //     this.candidateFunctions.push(candidateFunction);
      //   });
      // });
      store.dispatch("auth/getCandidateFunctions").then((response) => {
        if (response.status === 200) {
          this.candidateFunctions = store.state.auth.candidate_functions
        }
      })
    },
    fetchSubFunctions() {
      // axios.get('/sub_functions').then((response) => {
      //   response.data.sub_functions.forEach((subFunction) => {
      //     this.subFunctions.push(subFunction);
      //   });
      // });
      store.dispatch("auth/getSubFunctions").then((response) => {
        if (response.status === 200) {
          this.subFunctions = store.state.auth.sub_functions
        }
      })
    },
    fetchCountries() {
      // axios.get('/countries').then((response) => {
      //   response.data.countries.forEach((country) => {
      //     this.countries.push(country);
      //   });
      // });
      store.dispatch("auth/getCountries").then((response) => {
        if (response.status === 200) {
          this.countries = store.state.auth.countries
        }
      })
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    skipped() {
      axios.post("/job-oppurnity").then((response) => {
        if (response.data.status === "success") {
          this.$router.push({ name: "paid-consulting-form" })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
.logo_img {
  width: 120px !important;
  margin-left: -80px;
}
</style>
